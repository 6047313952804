/**
 * Site Entry Point for Parent and Child Theme Core
 */

/* ======== Global NPM Packages for Parent Theme Usage ======== */
//jQuery
//const $ = require("jquery");
// Owl Carousel
import "owl.carousel/dist/owl.carousel.min.js";
import "owl.carousel/dist/assets/owl.carousel.min.css";
import "owl.carousel/dist/assets/owl.theme.default.min.css";

// Lightbox2
import "lightbox2/dist/js/lightbox.min.js";
import "lightbox2/dist/css/lightbox.min.css";

// Iconify
import Iconify from "@iconify/iconify";

// SCSS
import "./sass/bidmark.scss";

// Bootstrap JS
import "bootstrap/dist/js/bootstrap.bundle.min.js";

// Initialize AOS:
import AOS from "aos";
import "aos/dist/aos.css";

// Initialize AOS & set the trigger point of the animations to the center of the screen:
// Ensure its inactive on mobile (under 992px):

AOS.init({
	offset: 500,
	duration: 600,
	easing: "ease-in-out",
	delay: 0,
	once: false,
	disable: window.innerWidth < 992,
});
